@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Saira+Condensed&display=swap');

body {
    
    background: #445925;
    font-family: 'Saira Condensed', sans-serif;
  }

  .nav a {
    text-decoration: none;
  }

  .nav a:visited {
    color: inherit; 
  }

  .App {
    padding-top: 10rem;
    background: rgb(32,42,14);
    background: linear-gradient(180deg, rgba(32,42,14,1) 0%, rgba(68,89,37,1) 100%);
  }

  .menu-item a {
    color: #000;
    text-decoration: none;
    font-size: 1.1rem;
    font-weight: 700;
    font-family: 'Saira Condensed', sans-serif;
  }

  .mobile-services {
    position: relative;
    top: 2px;
    margin-right: 4px;
  }

  .mobile-location {
    position: relative;
    top: 2px;
    margin-right: 4px;
  }

  .mobile-faq {
    position: relative;
    top: 3px;
    margin-right: 4px;
  }

  .logo-text {
    font-family: 'Abril Fatface', cursive;
    font-size: 2.6rem;
    color: #ec3e38;
    text-shadow: 1px 1px 2px #000;
    margin-left: 40px;
  }

  .logo-services {
    position: relative;
    top: 2px;
    margin-right: 4px;
  }

  .logo-location {
    position: relative;
    top: 2px;
    margin-right: 4px;
  }

  .logo-ffa {
    position: relative;
    top: 3px;
    margin-right: 4px;
  }

  .hero-bg-img {
    background: #172601;
    display: none;
  }

  .hero-bg-img img {
    width: 100%;
    height: 300px;
  }
  
  .btn-container i{
    margin-left: 8px;
    position: relative;
    top: 3px;
  }
.big-text {
  font-family: 'Abril Fatface', cursive;
  color: #ec3e38;
  text-shadow: 1px 1px 1px #000;
  font-size: 3rem;
  font-weight: 700;
}

.big-text p {
  padding: 0;
  margin: 0;
}



.main-content {
  background: #202a0e; 
  height: auto;
  padding: 6rem 4rem 2rem 4rem;
}

.main-content-container {
  display: flex;
}

.main-content-container a {
  text-decoration: none;
}

.main-content-container a:visited {
  color: inherit;
}

.main-content-text {
  width: auto;
}

.main-content-text h1 {
  font-family: 'Abril Fatface', cursive;
  font-size: 2.6rem;
  color: #f7cbba;
  text-shadow: 1px 1px 2px #000;
}

.main-content-text p {
  font-family: 'Saira Condensed', sans-serif;
  font-size: 1.3rem;
  color: #B4BEC9;
  text-shadow: 1px 1px 2px #000;
  width: 70%;
}

.footerr p, .footerr a {
  font-family: 'Saira Condensed', sans-serif;
  font-size: 1rem;
  color: #B4BEC9;
  text-shadow: 1px 1px 2px #000;
}

.footerr a {
  text-decoration: none;
}

.footerr a:hover {
  text-decoration: underline;
}

.main-content-text li {
  font-family: 'Saira Condensed', sans-serif;
  font-size: 1.3rem;
  color: #B4BEC9;
  text-shadow: 1px 1px 2px #000;
  list-style-type: none;
}

.main-content-text button {
  border: none;
  border-radius: 25px;
  padding: 0.5rem 2rem;
  background: #ec3e38;
  color: #B4BEC9;
  font-size: 1.3rem;
  font-weight: 700;
  font-family: 'Saira Condensed', sans-serif;
  text-shadow: 1px 1px 1px #000;
}

.main-content-text button:hover {
  cursor: pointer;
  background: #f7cbba;
  color: #172601;
  transition: 0.4s;
  color: #B4BEC9;
  transform: scale(1.02);
}

.main-content-img {
  background: #202a0e;
  width: auto;
  height: auto;
}

.main-content-img img {
  border-radius: 7px;
  width: 30%;
}

.first-row {
  margin: 1rem 0rem 0 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.first-row img {
  margin: 0.4rem;
  width: 800px;
  height: auto;
}

/* HINNAT */

.price-content {
  background: #202a0e; 
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;


}

.price-content-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.price-content-text {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 12rem;
}

.price-content-text h1 {
  font-family: 'Abril Fatface', cursive;
  font-size: 2.6rem;
  color: #f7cbba;
  text-shadow: 1px 1px 2px #000;
}

.price-content-text p {
  font-family: 'Saira Condensed', sans-serif;
  font-size: 1.3rem;
  color: #B4BEC9;
  text-shadow: 1px 1px 2px #000;
  width: 70%;
}

.infos-grid {
    text-align: center;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.grid {
    width: 60%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.grid-img {
    width: 20%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.grid-img img {
    margin-top: 2rem;
    width: 8rem;
}
/* 
@media screen and (max-width: 1600px) {

  .first-row-picture {
    display: none;
  }
} */

@media screen and (max-width: 460px) {

    .hero {
        background-image: url('./album/cover5.jpeg');
        background-size: cover;
        height: 40vh;
        background-position: 50% 50%;
        background-position-x: -9rem;
      }

    .infos-grid p {
        font-size: 0.8rem;
    }

    .infos-grid {
        text-align: center;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    
        
    }
    
    .grid {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    
    .grid-img {
        width: 20%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    
    .grid-img img {
        margin-top: 2rem;
        width: 4rem;
    }
}


.mika-div {
  background: rgb(32,42,14);
  background: linear-gradient(180deg, rgba(32,42,14,1) 0%, rgba(68,89,37,1) 100%);
}

.mika-div h1 {
  margin: 0;
  font-family: 'Abril Fatface', cursive;
  font-size: 2.6rem;
  color: #f7cbba;
  text-shadow: 1px 1px 2px #000;
  margin-bottom: 6rem;
}


.location-container {
    text-align: center;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    margin-bottom: 12rem;
    
}

.location-infos {
    margin: 1rem;
    width: 200px;
}

.location-infos p, h3, h4 {
  font-family: 'Saira Condensed', sans-serif;
  color: #B4BEC9;
  text-shadow: 1px 1px 2px #000;
}

.location-infos h4 {
  font-size: 1rem;
}


.location-infos h3 {
  font-size: 1.3rem;
}

.location-infos p {
  font-size: 1.3rem;
}

@media screen and (max-width: 460px) {

  .leaflet-container {
    width: 80% !important;
    max-width: 700px;
    height: 500px !important;
    border-radius: 15px;
  }

  .logo-text {
    margin-left: 0px;
  }

  
  .empty-box {
    display: none;
  }

    .hero-bg-img {
      background: #172601;
      display: flex;
    }

    .location-container {
        margin-top: 6rem;
        text-align: center;
        font-size: 0.8rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    
        margin-bottom: 12rem;
    }

    .main-content-img {
      display: none;
   }

   .main-content-text, .price-content-text, .main-content-text p {
      width: 100%;
      margin: 0 auto;
      text-align: center;
   }

   .main-content-text ul {
    text-align: center;
    padding-left: 0; /* Remove default left padding on ul */
    list-style-type: none; /* Remove default list bullets */
   }

   .main-content-text li {
      margin: 0;
      font-size: 1.1rem !important;
   }

   .main-content-text button {
      display: flex;
      margin: 0 auto;
   }

   .main-content-text h1, .price-content-text h1 {
      font-size: 2rem;
   }

   .main-content-text li {
    font-family: 'Saira Condensed', sans-serif;
    font-size: 1.3rem;
    color: #B4BEC9;
    text-shadow: 1px 1px 2px #000;
  }

  .footerr {
    height: auto;
    background-color: #172601;
    color: white;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  

 }

.leaflet-container {
    width: 100%;
    max-width: 700px;
    height: 700px;
    border-radius: 15px;
  }

  .img-carousel {
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    max-width: 800px;
  }
  .carousel {
    background: #172601;
    margin: 0 auto;
  }
  
  .carousel.carousel-slider img{
    height: 500px;
    object-fit: contain;
  }
  
  .secondary-container {
    min-height: 700px;
    background-image: url("./album/forest.svg");
    background-size: cover;
    object-fit: contain;
    width: 100%;
    height: auto;
    position: relative;
    top: 20px;
  }

  /* footer {
    height: auto;
    background-color: #172601;
    color: white;
    display: flex;
    align-items: center;
    flex-direction: column;
  } */

  .footerr {
    height: auto;
    background-color: #172601;
    color: white;
    display: flex;
    align-items: center;
  }
  
  .footer, .mid, .right {
    width: 100%;
    text-align: center;
  }

  .mid {
    margin-top: 2rem;
  }

  